import React from 'react';
import { Layout } from '../components/layout';
import { Hero } from '@/components/layout/header/hero';
import { StaticImage } from 'gatsby-plugin-image';
import { BRAV_LINK } from '@/constants/links';

export default function PagaLaPenale() {
  const pageTitle = 'Paga la penale';

  const Banner = () => (
    <StaticImage
      src="../images/banners/paga-la-penale.png"
      alt={pageTitle}
      quality={100}
    />
  );

  const Left = () => (
    <StaticImage
      src="../images/other/paga-la-penale.png"
      quality={100}
      alt="paga-la-penale"
    />
  );

  const Right = () => (
    <div>
      <div>
        <h2 className="h3 text-primary font-rubik">Paga la penale</h2>
        <p className="text-secondary font-oxygen mt-3 mb-5">
          Accedendo al sistema PASS, anche senza registrazione, potrai
          verificare e pagare la contravvenzione ricevuta.
          <br />
          Ti basterà inserire il numero dell’avviso, la data dell’infrazione e
          la targa.
          <br />
          Il sistema ricercherà la penale e ti guiderà nella procedura di
          pagamento online.
        </p>
      </div>
      <div>
        <a href={BRAV_LINK} target="_blank">
          <button className="btn btn-primary mb-3">Paga la penale</button>
        </a>
      </div>
    </div>
  );

  return (
    <Layout title={pageTitle}>
      <Hero title={pageTitle} banner={<Banner />} />
      <div className="container mt-5 mb-5">
        <div className="row gx-5">
          <div className="col-12 col-lg-5">
            <Left />
          </div>
          <div className="col-12 col-lg-7 mt-3">
            <Right />
          </div>
        </div>
      </div>
    </Layout>
  );
}
